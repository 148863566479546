import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import CardGeneral from './card-general'
import { Check } from 'react-feather'
import MadeInUSA from '../assets/icons/madeinusa'

const WhyUsUnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
  margin-top: -1em;
  margin-bottom: -1em;

  li {
    list-style-type: none;
    padding: 0;
    padding-left: 20px;
    margin-bottom: 20px;
    line-height: 1.4;
    position: relative;
    svg {
      border-radius: 50%;
      padding-top: 5px;
      stroke-width: 4px;
      stroke: #fff;
      margin-left: -35px;
      padding: 4px;
      position: absolute;
      top: 4px;
    }
  }
`
const MadeInTheUSALogo = styled.div`
  margin-top: -10px;
  margin-bottom: 15px;
`

const WhyUs = () => (
  <StaticQuery
    query={graphql`
      query WhyUsListQuery {
        site {
          siteMetadata {
            whyUs
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <CardGeneral title={'Why Us'} className={'card-certs-green'}>
            {/* <MadeInTheUSALogo>
       
       <MadeInUSA/>
        </MadeInTheUSALogo> */}
            <WhyUsUnorderedList className="whyuslist">
              <li>
                <Check
                  size={22}
                  className="bg-color-primary stroke-color-primary-lightest"
                />{' '}
                Family-Owned &amp; Operated
                <p>Sacramento-Based Design Build Firm</p>
              </li>
              <li>
                {' '}
                <Check
                  size={22}
                  className="bg-color-primary stroke-color-primary-lightest"
                />
                Full Service Project Management
                <p>Coordinate Project Details & Schedule</p>
              </li>

              <li>
                <Check
                  size={22}
                  className="bg-color-primary stroke-color-primary-lightest"
                />
                Total Personalization
                <p>All Options To Fully Customize</p>
              </li>

              <li>
                <Check
                  size={22}
                  className="bg-color-primary stroke-color-primary-lightest"
                />{' '}
                Custom Design, Architectural Plans & 3D Renderings
                <p>We Help Bring Your Project to Life</p>
              </li>
              <li>
                <Check
                  size={22}
                  className="bg-color-primary stroke-color-primary-lightest"
                />{' '}
                Permitting
                <p>Start Your Project With A Detailed Plan</p>
              </li>
            </WhyUsUnorderedList>
          </CardGeneral>
        </>
      )
    }}
  />
)

export default WhyUs
